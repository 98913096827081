import React, { useContext, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import tw, { css } from "twin.macro"
import Image from "../components/imagev2"
import CLink from "../components/cLink"
import each from "lodash.foreach"

import {
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share"

import ContentBlog from "../components/pwcomps/content_blog"
import { TransitionState } from "../helpers/DummyGatsbyPluginTransitionLink"
import { Spring, animated as a } from "@react-spring/web"
import Footer from "../components/footer"
import BreadCrumb from "../components/breadcrumb"
import Seo from "../components/seo"
import {GeneralContext} from "../contexts/generalContext";

const PageGeneral = props => {
  const data = useStaticQuery(graphql`
    query pageBlogEntryCategories {
      blogPageInCurrentLang: allSitePage(filter: {context: {pwid: {eq: 1}}}) {
        nodes {
          context {
            lang
            ruta
          }
        }
      }
      processwire {
        config {
          es {
            blog_categorias {
              ruta
              title
            }
          }
          en {
            blog_categorias {
              ruta
              title
            }
          }
          fr {
            blog_categorias {
              ruta
              title
            }
          }
          pt {
            blog_categorias {
              ruta
              title
            }
          }
        }
      }
    }
  `)
    const generalContext = useContext(GeneralContext)
    const { t } = generalContext
  const pageData = props.pageContext
  const { pageContext } = props
  const lang = pageContext.lang
  const categorias = data.processwire.config[lang].blog_categorias
  const categoriasPorRuta = useMemo(() => {
    const cats = {}
    each(categorias, categoria => {
      cats[categoria.ruta] = categoria
    })
    return cats
  })
  return (
    <>
      <Seo
        pageContext={pageContext}
      />
      <TransitionState>
        {({ transitionStatus, exit, entry }) => {
          const isMounting = ["entering", "entered"].includes(transitionStatus)
          const isExiting = transitionStatus === "exiting"
          return (
            <>
              <div css={tw`px-5 md:px-9 overflow-x-visible`}>
                <div
                  css={tw`max-w-screen-xl my-0 mx-auto px-0 font-obbody overflow-x-visible`}
                >
                  <article>
                    <div css={tw`relative`}>
                      <BreadCrumb
                        isMounting={isMounting}
                        pageContext={pageContext}
                      />
                      <Spring
                        to={{
                          transform: `translateY(${
                            isMounting ? "0rem" : isExiting ? "-2rem" : "10rem"
                          })`,
                          opacity: isMounting ? 1 : 0,
                        }}
                      >
                        {styles => (
                          <a.div style={styles}>
                            <div css={tw`relative`}>
                              <Image
                                objectFit={"contain"}
                                imageTablet={pageData.image}
                                imageMobile={pageData.image_mobile}
                                widths={["880px", "980px", "1200px"]}
                              />
                            </div>
                            <div tw="md:grid grid-cols-3 gap-5 py-4 md:py-8">
                              <div>
                                <div tw="border-t border-gris3 text-obmed uppercase text-gris4 py-2">
                                    {t.blog.category}{" "}
                                  {pageData.blog_categorias.map(
                                    (categoriaRuta, index) => {
                                      return (
                                        <li css={tw`inline-block`} key={index}>
                                          {index !== 0 && (
                                            <span css={tw` text-marron1 mx-1`}>
                                              /
                                            </span>
                                          )}
                                          <CLink
                                            css={tw` text-marron1 hover:text-gris1 `}
                                            to={
                                              categoriasPorRuta[categoriaRuta]
                                                .ruta
                                            }
                                          >
                                            {
                                              categoriasPorRuta[categoriaRuta]
                                                .title
                                            }
                                          </CLink>
                                        </li>
                                      )
                                    }
                                  )}
                                </div>
                                <div tw="border-t border-gris3 text-obmed  text-gris4 py-2">
                                  {pageData.blog_fecha}
                                </div>
                                <div tw="border-t border-gris3 text-obmed  text-gris4 py-2">
                                    {t.blog.sharePost}
                                  <div css={ShareButtons}>
                                    <FacebookShareButton
                                      url={pageContext.url}
                                      resetButtonStyle={false}
                                      quote={pageContext.title}
                                    >
                                      Facebook
                                    </FacebookShareButton>{" "}
                                    <LinkedinShareButton
                                      url={pageContext.url}
                                      resetButtonStyle={false}
                                      title={pageContext.title}
                                    >
                                      Linkedin
                                    </LinkedinShareButton>
                                  </div>
                                </div>
                              </div>
                              <div tw="col-span-2 pb-10" css={blogEntryContentCSS}>
                                <ContentBlog
                                  contents={pageData.content}
                                  lang={pageData.lang}
                                  isMounting={isMounting}
                                  isExiting={isExiting}
                                  pageRuta={pageContext.ruta}
                                />
                              </div>
                            </div>
                          </a.div>
                        )}
                      </Spring>
                    </div>
                  </article>
                </div>
              </div>
              <Footer isMounting={isMounting} lang={lang} langSettings={props.pageContext.langSettings} />
            </>
          )
        }}
      </TransitionState>
    </>
  )
}

export default PageGeneral

const ShareButtons = css`
  & button {
    ${tw`uppercase text-marron1`}
  }
`

const blogEntryContentCSS = css`
  & > *:first-child {
    ${tw`mt-0 md:mt-0`}
  }
`
